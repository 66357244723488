<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      hide-header
      footer-class="pt-0 border-0"
    >
      <div class="bg-white border-red">
        <div>
          <div class="container-check">
            <div class="check"></div>
          </div>
        </div>
        <div class="px-3 py-1">
          <p class="title">รายละเอียดบิล</p>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>เลขที่บิล</p>
            </b-col>
            <b-col class="text-right break-word">
              <p>{{ result.invoice }}</p>
            </b-col>
          </b-row>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>วัน-เวลาที่ชำระเงิน</p>
            </b-col>
            <b-col class="text-right">
              <p>
                {{
                  $moment(result.payment_date)
                    .add(543, 'years')
                    .format($formatDateNewFull)
                }}
                ({{
                  $moment(result.payment_date).format('H') < 13 ? 'AM' : 'PM'
                }})
              </p>
            </b-col>
          </b-row>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>ช่องทางการชำระเงิน</p>
            </b-col>
            <b-col class="text-right">
              {{ isFullPos ? paymentChannel.name : '-' }}
            </b-col>
          </b-row>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>ราคาสุทธิ</p>
            </b-col>
            <b-col class="text-right">
              <p>{{ result.price.price | numeral('0,0.00') }}</p>
            </b-col>
          </b-row>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>เงินทอน</p>
            </b-col>
            <b-col class="text-right">
              <p>
                {{
                  result.price_change < 0
                    ? 0
                    : result.price_change | numeral('0,0.00')
                }}
              </p>
            </b-col>
          </b-row>
        </div>
        <div class="px-3 py-2">
          <div class="title">รายละเอีดยลูกค้า</div>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>ชื่อ-นามสกุล</p>
            </b-col>
            <b-col class="text-right">
              <p>{{ result.user.name }}</p>
            </b-col>
          </b-row>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>ID</p>
            </b-col>
            <b-col class="text-right">
              <p>{{ result.user.member_id }}</p>
            </b-col>
          </b-row>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>คะแนนที่ได้รับ</p>
            </b-col>
            <b-col class="text-right">
              <p class="text-green">
                {{ result.user_point.central_point | numeral('0,0') }}
                <span
                  class="text-green"
                  v-if="result.user_point.branch_point != 0"
                >
                  (คะแนนสาขา
                  {{ result.user_point.branch_point | numeral('0,0') }})
                </span>
              </p>
            </b-col>
          </b-row>
          <b-row class="border-bottom py-2">
            <b-col>
              <p>คะแนนรวม</p>
            </b-col>
            <b-col class="text-right">
              <p>{{ result.user.point_all | numeral('0,0') }}</p>
            </b-col>
          </b-row>
          <b-row class="py-2">
            <b-col md="12" class="text-right">
              <router-link
                class="link-to"
                @click="handleBilling()"
                :to="`/transaction/detail/${result.detail.transaction_id}/${userGuid}`"
                >ดูรายละเอียดบิล</router-link
              >
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100 mb-3 text-center">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover"
            @click="hide('forget')"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';

export default {
  props: {
    paymentChannelList: {
      require: true,
      type: Array
    },
    isFullPos: {
      require: false,
      type: [Number, String]
    }
  },
  components: {
    OtherLoading
  },
  data() {
    return {
      result: {
        result: 1,
        message: null,
        point: 0,
        detail: {
          transaction_id: ''
        },
        price: {
          price: 0,
          used_coupon: []
        },
        user: {
          name: '',
          member_id: '',
          point_all: 0
        },
        invoice: '',
        payment_date: '',
        payment_channel_id: 1,
        payment_channel_name: '',
        price_change: 0,
        print_file: null,
        user_point: {
          branch_point: 0,
          central_point: 0
        }
      },
      isLoading: false,
      showModal: false,
      userGuid: '',
      paymentChannel: { id: 0, name: '' }
    };
  },
  methods: {
    async show(data, userGuid) {
      this.result = data;
      let filtered = await this.paymentChannelList.filter(
        item => parseInt(item.id) == this.result.payment_channel_id
      );
      this.paymentChannel = filtered[0];
      this.userGuid = userGuid;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async handleBilling(){
      await this.$store.dispatch("setMainLoading", true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem 0rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.container-check {
  background-color: #78b13f;
  border-radius: 50%;
  padding: 9px;
  width: 70px;
  height: auto;
  align-items: stretch;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 25px auto;
}
.check {
  display: inline-block;
  transform: rotate(45deg);
  height: 38px;
  width: 20px;
  border-radius: 2px;
  border-bottom: 8px solid white;
  border-right: 8px solid white;
  margin-bottom: 8px;
}
.container-check:after {
  content: 'Success !';
  position: absolute;
  bottom: -25px;
  color: #afafaf;
}
.text-green {
  color: #78b13f;
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.link-to {
  text-decoration: underline;
  color: #7c7a7a;
}
</style>
