<template>
  <div>
    <b-sidebar
      v-model="showModal"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-customer-List-title"
      @hidden="hide()"
      no-header
      :lazy="true"
    >
      <template>
        <div class="sidebar-content" id="sidebar-customer-List">
          <div class="filter">
            <div class="d-flex justify-content-between align-items-center p-3">
              <label class="header-text">Serial</label>
              <div class="pointer" @click="hide()">
                <b-icon icon="x-circle-fill"></b-icon>
              </div>
            </div>

            <div
              class="d-flex justify-content-start align-items-end px-3 py-2 filter"
            >
              <InputText
                v-model="filter.search"
                textFloat=""
                type="text"
                name="search_customer"
                faIcon="search"
                className="mb-2 w-100"
                @onEnter="handleSearch"
                @onClickIcon="handleSearch"
              />
              <div>
                <img
                  src="@/assets/images/icons/bin.png"
                  alt="delete"
                  class="pointer delete-icon"
                  center
                  width="32"
                  @click="clearSearch()"
                />
              </div>
            </div>
          </div>

          <div class="serial-list pb-3" id="serial-list" @scroll="loadMore">
            <template>
              <div
                class="p-2 my-2 bg-white pointer serial-item"
                v-for="(serial, index) in items"
                :key="index"
                @click="selectRow(serial)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <p
                      class="text-m-16"
                      :class="{
                        'text-gray-1':
                          productList.find(
                            (x) => x.serial === serial.serial
                          ) !== undefined 
                      }"
                    >
               
                      {{ serial.serial }}
                    </p>
                  </div>
                  <div
                    class="promotion-point text-m-16 text-right"
                    :class="{
                      'text-gray-1':
                        productList.find((x) => x.serial === serial.serial) !==
                          undefined 
                    }"
                  >
                    {{ serial.price }} บาท
                  </div>
                </div>
              </div>
            </template>
            <div v-if="isLoading" class="loading-panel">
              <OtherLoading />
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";

export default {
  components: { OtherLoading, InputText },
  props: {
    branchId: {
      type: Number,
      isRequired: true,
    },
    productList: {
      type: Array,
      isRequired: true,
    },
  },
  data() {
    return {
      indexEdit: 0,
      isLoading: false,
      showModal: false,
      filter: {
        search: "",
        page: 1,
        take: 20,
      },
      now: null,
      items: [],
      listWrapper: null,
      rows: 0,
      promotionSelected: [
        {
          item: [],
          sap: [],
        },
      ],
      select_serial: null,
      lasPage: 0,
      indexSerial: null,
    };
  },

  methods: {
    clearSearch() {
      this.filter.page = 1;
      this.filter.search = "";
      this.items = [];
      this.getSerial();
    },
    async getSerial() {
      if (this.isBusy) return;
      else {
        this.isLoading = true;
        this.isBusy = true;

        if (this.filter.search) {
          this.items = [];
        }
        await this.axios
          .post(
            `${this.$baseUrl}/product/get_product_serial/${this.product_id}/${this.branchId}`,
            this.filter
          )
          .then(async (data) => {
            if (data.result == 1) {
              if (this.items.length && !this.filter.search) {
                this.items.push(...data.detail.data);
              } else {
                this.items = data.detail.data;
              }

              this.rows = data.detail.count;
              let float = data.detail.count / this.filter.take;
              this.lastPage = Math.ceil(float);
              this.isLoading = false;
            }
          });

        this.isBusy = false;
      }
    },
    loadMore() {
      const masonry = document.querySelector("#serial-list");

      if (
        masonry.scrollTop + masonry.clientHeight >=
        masonry.scrollHeight - 20
      ) {
        if (this.lastPage == this.filter.page || this.isBusy) return;

        this.filter.page += 1;
        this.getSerial();
      }
    },
    async show(product, index) {
      this.indexSerial = index;
      this.product_id = product.id;
      if (product.serial) {
        this.select_serial = product.serial;
      } else {
        this.select_serial = null;
      }
      this.showModal = true;
      await this.getSerial();
    },
    hide() {
      this.filter.page = 1;
      this.filter.search = "";
      this.items = [];
      this.showModal = false;
    },
    async selectRow(item) {
      if (this.productList.find((x) => x.serial === item.serial) !== undefined)
        return;
      else {
        this.select_serial = item.serial;
        this.$emit("selectSerial", item, this.product_id, this.indexSerial);
      }
    },
    async unselectRow() {
      this.select_serial = "";
      this.$emit("unselectItem", null, this.product_id, this.indexSerial);
    },
    async handleSearch() {
      if (!this.isLoading) {
        await this.getSerial();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;

  .nav-link {
    min-width: 107px !important;
  }
}

.sidebar-content {
  height: calc(100vh - 9px);
  display: flex;
  flex-direction: column;

  .filter {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ebe5e5;
  }

  .serial-list {
    overflow: auto;
    flex: 1;

    .serial-item {
      border-bottom: 1px solid #ebe5e5;
      min-height: 65px;
    }

    .loading-panel {
      height: 50px;
      div {
        height: 100%;
      }
    }
  }
}

::v-deep .custom-control-label {
  width: 100%;
}

::v-deep .b-sidebar > .b-sidebar-body {
  overflow: hidden;
}

.custom-label {
  margin-top: -1px;
}
.delete-icon {
  margin-bottom: 11px;
  margin-left: 8px;
}

::v-deep .b-sidebar {
  width: 90vw;
}
</style>
