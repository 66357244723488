<template>
  <div class="relative">
    <template v-if="isMobile">
      <MobilePanel
        :customerList="customerList"
        :branchId="branchId"
        :form="form"
        :user="user"
        :transaction="transaction"
        :filter="filter"
        :headerDiscountOption="headerDiscountOption"
        :promotionList="promotionList"
        :itemDiscountList="itemDiscountList"
        :selecedHeaderDiscountIdList="selecedHeaderDiscountIdList"
        :productSelected="productSelected"
        :headerDiscountItems="headerDiscountItems"
        @setUserSelect="setUserSelect"
        @handleSearchCustomer="handleSearchCustomer"
        @getUserDetail="getUserDetail(user.user_guid, true)"
        @addProduct="addProduct"
        @addCredit="addCredit"
        @addProductList="addProductList"
        @upProductQuantity="upProductQuantity"
        @downProductQuantity="downProductQuantity"
        @addCoupon="addCoupon"
        @deleteCoupon="deleteCoupon"
        @setSelectProduct="setSelectProduct"
        @getDiscount="getDiscount"
        @addItemDiscount="addItemDiscount"
        @addSapDiscount="addSapDiscount"
        @deleteItemDiscount="deleteItemDiscount"
        @deleteSapDiscount="deleteSapDiscount"
        @submit="submit"
        @handleQuantity="handleKeyQuantity"
        @handleSelectSerial="handleSelectSerial"
      >
        <template #filter="{ handleMobileSearchCustomer, handleMobileSearch }">
          <InputText
            v-model="filter.member.search"
            type="text"
            faIcon="search"
            className="mb-2 rounded bg-second"
            placeholder="ค้นหาลูกค้า"
            @onClickIcon="handleMobileSearch"
            textField="telephone"
            @onEnter="handleMobileSearch"
          />
        </template>
        <template #form>
          <b-row class="px-2">
            <b-col cols="6">
              <InputText
                textFloat="รหัสพนักงาน"
                v-model="form.saleId"
                ref="saleIdInput"
                placeholder="รหัสพนักงาน"
                @onEnter="handleEnter"
                type="text"
            /></b-col>
            <b-col cols="6">
              <InputText
                textFloat="หมายเลขบิล"
                :value="form.invoiceNo"
                placeholder="หมายเลขบิล"
                type="text"
                :disabled="isFullPos == 1"
                @onKeydown="handleInvoiceNo"
              />
            </b-col>
          </b-row>
        </template>
      </MobilePanel>
    </template>
    <template v-else>
      <div class="d-flex">
        <div class="pt-1 layout-left">
          <b-row no-gutters class="px-3 head-total h-auto">
            <b-col>
              <div class="bg-secondary">
                <p>Total QTY</p>
                <p>(จำนวนสุทธิ)</p>
                <p class="points">
                  {{ transaction.quantity | numeral("0,0") }}
                </p>
              </div>
            </b-col>
            <b-col>
              <div class="bg-secondary">
                <p>Total Amount</p>
                <p>(ราคารวม)</p>
                <p class="points">
                  {{ transaction.price | numeral("0,0.00") }}
                </p>
              </div>
            </b-col>
            <b-col>
              <div class="bg-secondary">
                <p>Total Discount</p>
                <p>(ส่วนลดรวม)</p>
                <p class="points">
                  {{ transaction.discount | numeral("0,0.00") }}
                </p>
              </div>
            </b-col>
            <b-col>
              <div class="bg-secondary">
                <p>Total Net Amount</p>
                <p>(ราคาสุทธิ)</p>
                <p class="points">
                  {{ transaction.netPrice | numeral("0,0.00") }}
                </p>
              </div>
            </b-col>
            <b-col v-if="$store.state.isCenterPoint == 0">
              <div class="bg-secondary">
                <p>Branch Point</p>
                <p>(คะแนนสะสมสาขา)</p>
                <p class="points">
                  {{ transaction.center_point | numeral("0,0") }}
                </p>
              </div>
            </b-col>
            <b-col>
              <div class="bg-secondary">
                <p>Total Point</p>
                <p>(คะแนนสะสม)</p>
                <p class="points">
                  {{ transaction.raw_point | numeral("0,0") }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="px-3 py-1">
            <b-col class="pr-0">
              <InputText
                v-model="filter.search"
                textFloat="EAN/Article"
                placeholder="EAN/Article"
                type="text"
                @onEnter="searchProduct"
              >
                <template v-slot:text>
                  <u
                    @click="searchProduct"
                    class="text-desc-message text-black font-weight-bold pointer"
                    >ค้นหา</u
                  >
                </template>
              </InputText>
            </b-col>
            <b-col class="pr-0">
              <InputText
                v-model="form.saleId"
                textFloat="Salesperson ID"
                placeholder="Salesperson ID"
                type="text"
              />
            </b-col>
            <b-col class="pr-0">
              <InputText
                v-model="form.invoiceNo"
                textFloat=" "
                placeholder="POS/MIM Ref No."
                type="text"
                @onKeydown="handleInvoiceNo"
                :disabled="isFullPos == 1"
              >
                <template v-slot:text
                  ><div class="d-flex justify-content-between">
                    POS/MIM Ref No.
                    <b-form-checkbox
                      size="sm"
                      v-model="user_get_point"
                      :value="0"
                      :unchecked-value="1"
                      @change="calculateTransaction"
                    >
                      No Point
                    </b-form-checkbox>
                  </div>
                </template>
              </InputText>
            </b-col>
            <b-col
              cols="1"
              class="d-flex justify-content-center align-items-end"
            >
              <img
                src="@/assets/images/icons/bin.png"
                alt="delete"
                class="pointer delete-icon"
                center
                width="40"
                @click="clearPage"
              />
            </b-col>
          </b-row>
          <div class="m-3">
            <b-row class="mx-0 text-center header-products">
              <b-col cols="1" class="header-products-item">
                <p>จำนวน</p>
                <p>Qty</p>
              </b-col>
              <b-col md="4" class="header-products-item">
                <p>รายละเอียดสินค้า/โปรโมชั่น</p>
                <p>Description/Promotion</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคาต่อหน่วย</p>
                <p>Unit Price</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคารวม</p>
                <p>Total</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ส่วนลด</p>
                <p>Discount</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคาสุทธิ</p>
                <p>Net</p>
              </b-col>
            </b-row>
          </div>
          <div class="product-list-container px-3">
            <div class="item-products" v-if="form.credit">
              <b-row :class="['product-panel align-items-center mx-0 mb-2']">
                <b-col
                  cols="1"
                  class="d-flex justify-content-center align-items-center border-product py-4"
                >
                  <div class="delete-credit">
                    <font-awesome-icon
                      icon="trash-alt"
                      title="delete-btn"
                      class="pointer"
                      @click="deleteCredit"
                    />
                  </div>
                </b-col>
                <b-col md="4" class="pointer">
                  <p>วงเงินเครดิต</p>
                  <p class="product-info">
                    วงเงินที่ใช้ได้:
                    {{ form.credit.total_price | numeral("0,0.00") }} บาท
                  </p>
                </b-col>
                <b-col class="price">
                  <p>-</p>
                </b-col>
                <b-col class="price">
                  <p>{{ form.credit.total_price | numeral("0,0.00") }}</p>
                </b-col>
                <b-col class="price">
                  <p>{{ form.credit.discount | numeral("0,0.00") }}</p>
                </b-col>
                <b-col class="price">
                  <p>{{ form.credit.net_price | numeral("0,0.00") }}</p>
                </b-col>
              </b-row>
            </div>

            <div class="item-products" v-if="form.product.length > 0">
              <b-row
                v-for="(product, index) in form.product"
                :key="index"
                @click="setSelectProduct({ index: index, ...product })"
                :class="[
                  'product-panel align-items-center mx-0 mb-2 py-1',
                  { selected: productSelected.index == index },
                ]"
              >
                <b-col cols="1" class="text-center border-product py-2">
                  <p @click="downProductQuantity(index)">
                    <font-awesome-icon
                      icon="minus-circle"
                      title="down-btn"
                      class="pointer"
                    />
                  </p>
                  <p
                    v-if="!clickQuantity || product.serial"
                    @click="changeQuantity(product.serial)"
                    class="my-0"
                  >
                    {{ product.quantity }}
                  </p>
                  <InputText
                    v-else-if="clickQuantity && !product.serial"
                    v-model="product.quantity"
                    @blur="handleKeyQuantity(index, product.quantity)"
                    @onEnter="handleKeyQuantity(index, product.quantity)"
                    class="my-1 text-quantity d-flex justify-content-center"
                    >{{ product.quantity }}</InputText
                  >
                  <p @click="upProductQuantity(index)">
                    <font-awesome-icon
                      icon="plus-circle"
                      title="up-btn"
                      class="pointer"
                      :class="{ 'text-gray': product.serial }"
                    />
                  </p>
                </b-col>
                <b-col md="4" class="pointer">
                  <p>{{ product.barcode }}</p>
                  <p class="product-info">{{ product.name }}</p>

                  <p
                    v-if="!product.serial && product.is_serial"
                    class="product-info"
                  >
                    Serial Number :
                    <span
                      class="text-link"
                      @click="selectSerial(product, index)"
                      >กรุณาระบุ Serial Number</span
                    >
                  </p>
                  <p
                    v-else-if="product.serial && product.is_serial"
                    class="product-info"
                  >
                    Serial Number : {{ product.serial }}
                    <span
                      class="text-link"
                      @click="selectSerial(product, index)"
                      >แก้ไข</span
                    >
                  </p>
                  <p v-if="product.promotion_detail" class="product-info">
                    {{ product.promotion_detail.textPromotion }}
                  </p>
                </b-col>
                <b-col class="price">
                  <div
                    v-if="
                      product.promotion_detail &&
                      product.promotion_detail.normal_unit_price !=
                        product.promotion_detail.promo_unit_price
                    "
                  >
                    <p>
                      {{
                        product.promotion_detail.net_price_per_item
                          | numeral("0,0.00")
                      }}
                    </p>
                    <p class="normal-unit-price">
                      {{ product.price | numeral("0,0.00") }}
                    </p>
                  </div>
                  <p>{{ product.price | numeral("0,0.00") }}</p>
                </b-col>
                <b-col class="price">
                  <p v-if="product.serial">
                    {{ product.price | numeral("0,0.00") }}
                  </p>
                  <p v-else-if="product.promotion_detail">
                    {{
                      (product.promotion_detail.promo_unit_price *
                        product.promotion_detail.q)
                        | numeral("0,0.00")
                    }}
                  </p>
                </b-col>
                <b-col class="price">
                  <p v-if="product.promotion_detail">
                    {{ product.promotion_detail.discount | numeral("0,0.00") }}
                  </p>
                </b-col>
                <b-col class="price">
                  <p v-if="product.promotion_detail">
                    {{ product.promotion_detail.net_price | numeral("0,0.00") }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <div
              v-if="!form.credit && !form.product.length"
              class="p-2 wrap-no-product"
            >
              <div class="no-product">
                ไม่มีสินค้าในตะกร้า<br />
                กรุณาสแกนบาร์โค้ดหรือค้นหาสินค้า
              </div>
            </div>
          </div>
        </div>
        <div class="layout-right">
          <b-row class="mb-2">
            <b-col>
              <div class="px-3 pt-lg-2 pt-1">
                <InputText
                  v-model="filter.member.search"
                  type="text"
                  faIcon="search"
                  className="mb-1 mb-lg-2 rounded bg-second"
                  @onChange="handleSearchCustomer"
                  @onClickIcon="handleSearch"
                  @selectAutoComplete="setUserSelect"
                  :optionsAutoComplete="customerList"
                  textField="telephone"
                  @onEnter="handleSearch"
                />
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">ชื่อ-สกุล</p>
                  </b-col>
                  <b-col>
                    <div class="display-user-value">
                      {{
                        `${user.first_name_th || ""} ${user.last_name_th || ""}`
                      }}

                      <template v-if="user.user_guid">
                        <font-awesome-icon
                          icon="check"
                          class="text-success"
                          v-if="
                            user.consent_status == 'Consent' ||
                            user.consent_status == 'Direct consent'
                          "
                        ></font-awesome-icon>
                        <font-awesome-icon
                          icon="times"
                          class="text-danger"
                          v-else
                        ></font-awesome-icon>
                      </template>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">เบอร์โทรศัพท์</p>
                  </b-col>
                  <b-col>
                    <InputText
                      v-model="user.telephone"
                      type="text"
                      name="telephone"
                      className="input-border-0 input-h text-m-14"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">หมายเลขสมาชิก</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="user.member_id"
                      type="text"
                      name="member_id"
                      className="input-border-0 input-h text-m-14"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">ระดับสมาชิก</p>
                  </b-col>
                  <b-col>
                    <div class="display-user-value">
                      {{
                        user.member_level_id ? `${user.member_level_name}` : ""
                      }}
                      <span
                        v-if="user.upgrade_tier_id != 0 && user.upgrade_tier_id"
                      >
                        ({{
                          `อีก
                        ${
                          user.upgrade_baht
                            ? user.upgrade_baht.toLocaleString()
                            : ""
                        } เปลี่ยนระดับเป็น ${user.upgrade_tier_name}`
                        }})
                      </span>
                      <span
                        v-else-if="
                          user.downgrade_tier_id != 0 &&
                          user.downgrade_tier_id &&
                          user.maintain_baht > 0
                        "
                      >
                        ({{
                          `อีก
                        ${
                          user.maintain_baht
                            ? user.maintain_baht.toLocaleString()
                            : ""
                        } รักษาระดับ`
                        }})
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">วันเกิด</p>
                  </b-col>
                  <b-col>
                    <div class="display-user-value">
                      <template v-if="user.birthday">
                        {{ $moment(user.birthday).format("DD/MM/YYYY") }}
                        <span>{{ userBirthday }}</span>
                      </template>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  class="align-items-center mb-1"
                  v-if="$store.state.isCenterPoint == 0"
                >
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">คะแนนสะสมสาขา</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="user.user_guid ? user.point.toLocaleString() : ''"
                      type="text"
                      name="point"
                      className="input-border-0 input-h text-m-14"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">คะแนนสะสมทั้งหมด</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="
                        user.user_guid ? user.center_point.toLocaleString() : ''
                      "
                      type="text"
                      name="center_point"
                      className="input-border-0 input-h text-m-14"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">วงเงินเครดิต</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="user.user_guid ? user.credit : ''"
                      type="text"
                      name="credit"
                      className="input-border-0 input-h text-m-14"
                      isDisplay
                    />
                  </b-col>
                </b-row>
                <!-- <b-row class="align-items-center">
                  <b-col cols="4" class="pr-0">
                    <p class="text-title">PDPA</p>
                  </b-col>
                  <b-col>
                    <InputText
                      :value="`${user.consent_status}`"
                      type="text"
                      name="valid_user"
                      className="input-border-0 input-h text-m-14"
                      isDisplay
                    />
                  </b-col>
                </b-row> -->
              </div>
            </b-col>
          </b-row>
          <div class="d-inline px-3">
            <router-link :to="`/transaction?memberId=${user.member_id}`">
              <b-button
                variant="primary-color"
                class="btn-main-custom mr-1 my-2"
                :disabled="!user.user_guid"
              >
                รายการขายย้อนหลัง
              </b-button>
            </router-link>
            <b-button
              variant="primary-color"
              class="btn-main-custom mr-1 my-2"
              :disabled="!user.user_guid"
              @click="editMember"
            >
              แก้ไขข้อมูลลูกค้า
            </b-button>
            <b-button
              :to="`redeem${
                user.user_guid ? '?telephone=' + user.telephone : ''
              }`"
              variant="primary-color bgc-secondary"
              class="btn-gift"
            >
              <font-awesome-icon icon="gift" />
            </b-button>
          </div>

          <div class="mt-1 tab-discount">
            <b-tabs v-model="indexEdit" fill>
              <b-tab
                :title="`โปรโมชั่น (${
                  productSelected &&
                  productSelected.index !== undefined &&
                  promotionList[productSelected.index]
                    ? promotionList[productSelected.index].length
                    : 0
                })`"
              >
                <div
                  v-for="(promotion, i) in promotionList[productSelected.index]"
                  :key="i"
                  class="d-flex justify-content-between p-2 border-bottom"
                >
                  <b-form-checkbox-group
                    v-if="form.product.length"
                    id="checkbox-promotion"
                    name="checkbox-promotion"
                    v-model="form.product[productSelected.index].sap_discount"
                    @change="onChangeSapDiscount"
                  >
                    <b-form-checkbox
                      :value="{
                        ...promotion,
                      }"
                    >
                      <div class="promotion-name">{{ promotion.name }}</div>
                    </b-form-checkbox>
                    <div
                      @click="adjustDiscount(promotion, 'promotion')"
                      class="promotion-edit"
                      v-if="promotion.custom_header == 1"
                    >
                      ปรับส่วนลด
                    </div>
                  </b-form-checkbox-group>
                  <div class="promotion-right">
                    <span class="promotion-result" v-if="IsHeaderItemDiscount">
                      <span v-if="promotion.promotion_value == 0">
                        <span v-if="promotion.point >= 0">
                          ได้รับ {{ promotion.point | numeral("0,0") }}</span
                        >
                      </span>
                      <span v-else>
                        ส่วนลด
                        {{ promotion.promotion_value | numeral("0,0") }}</span
                      >
                    </span>
                    <span>
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? "แต้ม"
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == "percent"
                          ? "%"
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == "amount"
                          ? "บาท"
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == "point"
                          ? "แต้ม"
                          : ""
                      }}
                    </span>
                    <div
                      class="promotion-point"
                      v-if="promotion.required_point > 0"
                    >
                      ใช้แต้ม {{ promotion.required_point }} P
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab
                :title="`ส่วนลดรายการ (${
                  itemDiscountList[productSelected.index]
                    ? itemDiscountList[productSelected.index].length
                    : 0
                })`"
              >
                <div
                  v-for="(promotion, i) in itemDiscountList[
                    productSelected.index
                  ]"
                  :key="i"
                  class="d-flex justify-content-between p-2 border-bottom"
                >
                  <b-form-checkbox-group
                    v-if="
                      form.product.length && form.product[productSelected.index]
                    "
                    id="checkbox-promotion-item"
                    v-model="form.product[productSelected.index].item_discount"
                    name="checkbox-promotion-item"
                    @change="onChangeItemDiscount"
                  >
                    <b-form-checkbox
                      :value="{
                        ...promotion,
                      }"
                    >
                      <div class="promotion-name">{{ promotion.name }}</div>
                    </b-form-checkbox>
                    <div
                      @click="adjustDiscount(promotion, 'item')"
                      class="promotion-edit"
                      v-if="promotion.custom_header == 1"
                    >
                      ปรับส่วนลด
                    </div>
                  </b-form-checkbox-group>
                  <div class="promotion-right">
                    <span class="promotion-result" v-if="IsHeaderItemDiscount">
                      <span v-if="promotion.promotion_value == 0">
                        <span v-if="promotion.point >= 0">
                          ได้รับ {{ promotion.point | numeral("0,0") }}</span
                        >
                      </span>
                      <span v-else>
                        ส่วนลด
                        {{ promotion.promotion_value | numeral("0,0") }}</span
                      >
                    </span>
                    <span>
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? "แต้ม"
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == "percent"
                          ? "%"
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == "amount"
                          ? "บาท"
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == "point"
                          ? "แต้ม"
                          : ""
                      }}
                    </span>
                    <div
                      class="promotion-point"
                      v-if="promotion.required_point > 0"
                    >
                      ใช้แต้ม {{ promotion.required_point }} P
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="`ส่วนลดท้ายบิล (${headerDiscountOption.length})`">
                <div class="wrap-nav-content">
                  <div
                    v-for="(promotion, i) in headerDiscountOption"
                    :key="i"
                    :class="[
                      'd-flex justify-content-between p-2 border-bottom',
                    ]"
                  >
                    <b-form-checkbox-group
                      id="checkbox-promotion-header"
                      v-model="form.promotion"
                      name="checkbox-promotion-header"
                      @change="handleselectPromotion"
                    >
                      <b-form-checkbox
                        :value="{
                          ...promotion,
                        }"
                      >
                        <div class="promotion-name">
                          {{ promotion.name }}
                        </div>
                      </b-form-checkbox>
                      <br />
                      <b-button
                        variant="link"
                        @click="adjustDiscount(promotion, 'header')"
                        class="promotion-edit"
                        v-if="promotion.custom_header == 1"
                      >
                        ปรับส่วนลด
                      </b-button>
                    </b-form-checkbox-group>
                    <div class="promotion-right">
                      <span
                        class="promotion-result"
                        v-if="IsHeaderItemDiscount"
                      >
                        <span v-if="promotion.promotion_value == 0">
                          <span v-if="promotion.point >= 0">
                            ได้รับ {{ promotion.point | numeral("0,0") }}</span
                          >
                        </span>
                        <span v-else-if="promotion.custom_header_discount">
                          {{
                            promotion.custom_header_discount | numeral("0,0")
                          }}</span
                        >
                        <span v-else>
                          ส่วนลด
                          {{ promotion.promotion_value | numeral("0,0") }}</span
                        >
                      </span>
                      <span>
                        {{
                          promotion.point >= 0 && promotion.promotion_value == 0
                            ? "แต้ม"
                            : promotion.promotion_discount_type_id == 1 ||
                              promotion.discount_type == "percent"
                            ? "%"
                            : promotion.promotion_discount_type_id == 2 ||
                              promotion.discount_type == "amount"
                            ? "บาท"
                            : promotion.promotion_discount_type_id == 3 ||
                              promotion.discount_type == "point"
                            ? "แต้ม"
                            : ""
                        }}
                      </span>
                      <div
                        class="promotion-point"
                        v-if="promotion.required_point > 0"
                      >
                        ใช้แต้ม {{ promotion.required_point }} P
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="panel-coupon p-2 mt-4">
            <InputText
              textFloat="คูปอง"
              :value="selectedCouponName"
              type="text"
              name="search"
              hasIcon
              disabled
              className="rounded"
              placeholder="ไม่มีการใช้คูปอง"
            >
              <template v-slot:icon>
                <img
                  src="@/assets/images/icons/coupon.png"
                  alt="coupon"
                  class="img-coupon"
                  center
                  @click="handleCoupon"
                />
              </template>
            </InputText>
          </div>
          <div>
            <b-button variant="submit" @click="submit"> สั่งซื้อ </b-button>
          </div>
        </div>
      </div>
    </template>
    <ModalCustomerList
      :isMobile="isMobile"
      ref="modalCustomerList"
      @setUserSelect="setUserSelect"
    />
    <ModalSelectSerial
      ref="modalSerialList"
      @selectSerial="handleSelectSerial"
      @unselectItem="handleSelectSerial"
      :branchId="branchId"
      :productList="form.product"
    />
    <ModalEditMember
      ref="modalEditMember"
      @success="getUserDetail(user.user_guid, true)"
    />
    <ModalProductList
      :isMobile="isMobile"
      ref="modalProductList"
      @addCredit="addCredit"
      @setSelectProduct="addProduct"
    />
    <ModalAdjustDiscount
      ref="modalAdjustDiscount"
      @update="updateHeaderDiscount"
    />
    <ModalCoupon
      ref="modalCoupon"
      :isMobile="isMobile"
      @addCoupon="addCoupon"
      @deleteCoupon="deleteCoupon"
    />
    <ModalAlertConfirm
      ref="modalAlertConfirm"
      :msg="alertMsg"
      :text="alertText"
      @confirm="confirmUseBirthday"
    />
    <ModalAlertConfirm
      ref="modalAlertConfirmTime"
      :msg="alertMsg"
      :text="alertText"
      @confirm="submitTransaction"
    />
    <ModalBillDetails
      ref="modalBillDetails"
      :paymentChannelList="paymentChannelList"
      :isFullPos="isFullPos"
    />
    <ModalPayment
      ref="modalPayment"
      :paymentChannelList="paymentChannelList"
      @confirm="confirmPayment"
    />
    <ModalOTP ref="modalOtp" @success="otpSubmit(1)" />
    <ModalEditProduct
      ref="ModalEditProduct"
      :user="user"
      :headerDiscountOption="headerDiscountOption"
      :promotionList="promotionList"
      :itemDiscountList="itemDiscountList"
      :data="form"
      :IsHeaderItemDiscount="IsHeaderItemDiscount"
      :productSelected="productSelected"
      :promotionsBirthday="promotionsBirthday"
      :checkedBirthday="checkedBirthday"
      :branchId="branchId"
      @onOpenEditHeaderDiscountModal="openModalEditHeaderDiscount"
      @onEditProduct="editProduct"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalCustomerList from "./components/ModalCustomerList";
import ModalEditMember from "./components/ModalEditMember";
import ModalProductList from "./components/ModalProductList";
import ModalAdjustDiscount from "./components/ModalAdjustDiscount";
import ModalCoupon from "./components/ModalCoupon";
import ModalBillDetails from "./components/ModalBillDetails";
import ModalPayment from "./components/ModalPayment";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import { mapGetters } from "vuex";
import ModalEditProduct from "./components/ModalEditProduct";
import ModalOTP from "./components/ModalOTP";
import MobilePanel from "./mobile/MobilePanel.vue";
import {
  required,
  requiredIf,
  minLength,
  minValue,
} from "vuelidate/lib/validators";
import ModalSelectSerial from "./components/ModalSelectSerial.vue";

export default {
  components: {
    ModalSelectSerial,
    ModalOTP,
    InputText,
    ModalCustomerList,
    ModalEditMember,
    ModalProductList,
    ModalAdjustDiscount,
    ModalCoupon,
    ModalBillDetails,
    ModalAlertConfirm,
    InputDatePickerFilter,
    ModalPayment,
    ModalEditProduct,
    MobilePanel,
  },
  name: "HomeDesktop",
  computed: {
    selectedCouponName() {
      let filtered = this.selectedCouponList.filter((item) =>
        this.selecedHeaderDiscountIdList.includes(item.id)
      );
      filtered = filtered.map((coupon, index) => coupon.name);
      return filtered.toString();
    },
    selecedHeaderDiscountIdList() {
      let promotion = this.form.promotion;
      return promotion.map((promotion, index) => promotion.id);
    },
    promotionsBirthdayId() {
      let promotion = this.promotionsBirthday;
      return promotion.map((promotion, index) => promotion.id);
    },
    userBirthday() {
      let diffYear =
        this.$moment().years() - this.$moment(this.user.birthday).years();

      let birthday = this.$moment(this.user.birthday).add(diffYear + 1, "year");
      let countBirthDay = "";
      if (birthday.years() == "1753") return "";
      var a = birthday;
      var b = this.$moment();
      var years = a.diff(b, "year");
      b.add(years, "years");
      var months = a.diff(b, "months");
      b.add(months, "months");
      var days = a.diff(b, "days");

      if (birthday.month() == this.$moment().month())
        countBirthDay = "วันเกิดเดือนนี้";
      else if (months == 0) countBirthDay = "วันเกิดอีก " + days + " วัน";
      else countBirthDay = "วันเกิดอีก " + (Number(months) + 1) + " เดือน";
      return this.user.birthday ? `(${diffYear} ปี, ${countBirthDay})` : "";
    },
    ...mapGetters(["isLoading"]),

    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
      pointSystem: "getPointSystem",
      invoiceLength: "getInvoiceLength",
      isInvoiceLeadingZero: "getIsInvoiceLeadingZero",
      dateTimeFix: "getDateTimeFix",
      tempRegisiter: "getTempRegisiter",
      isFullPos: "getIsFullPos",
      allowPastCoupon: "getAllowPastCoupon",
      isSpa: "getSpa",
      isBooth: "getBooth",
    }),
  },
  data() {
    return {
      clickQuantity: false,
      isBusy: false,
      billDetail: null,
      filter: {
        search: "",
        saleId: "",
        invoiceNo: "",
        member: {
          search: "",
        },
      },
      transaction: {
        netPrice: 0,
        discount: 0,
        quantity: 0,
        center_point: 0,
        point: 0,
        sumPoint: 0,
      },
      IsHeaderItemDiscount: true,
      form: {
        saleId: "",
        invoiceNo: "",
        product: [],
        // promotions: [],
        selectedListPromotionId: [],
        promotionCustom: [],
        promotion: [],
        credit: null,
        user_guid: "",
      },
      credit: {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: null,
      },
      productSelected: {
        id: 0,
        q: 0,
        custom_price: 0,
        group_barcode: "",
        sap_discount: [],
        item_discount: [],
        index: 0,
      },
      user: {
        center_point: 0,
        created_time: "",
        credit: 0,
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        member_level_id: 0,
        member_level_name: "",
        point: 0,
        telephone: "",
        user_guid: "",
        user_id: 0,
        valid_user: 1,
        birthday: "",
        consent_status: "",
        sumPoint: 0,
        downgrade_tier_id: 0,
        upgrade_tier_id: 0,
        maintain_baht: 0,
        upgrade_baht: 0,
        custom_field: [],
      },
      selectedCouponList: [],
      promotionList: [],
      itemDiscountList: [],
      headerDiscountOption: [],
      headerDiscountSelect: [],
      indexEdit: 2,
      checkedBirthday: false,
      alertMsg: "",
      alertText: "",
      promotionsBirthday: [],
      customerList: [],
      calculateResult: true,
      paymentChannelList: [],
      isMobile: false,
      headerDiscountItems: [],
      select_serial: [],
      user_get_point: 1,
    };
  },
  validations() {
    return {
      form: {
        saleId: { required },
        invoiceNo: {
          required: requiredIf(function (item) {
            return this.isFullPos == 0;
          }),
        },
        product: { required, minLength: minLength(1) },
      },
    };
  },
  watch: {
    tempRegisiter: {
      handler(newValue, oldValue) {
        let user = {
          user_guid: newValue.userGuid,
        };

        if (
          !newValue.userGuid ||
          (oldValue && oldValue.userGuid === newValue.userGuid)
        )
          return;
        this.setUserSelect(user);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getPaymentChannel();
    this.getHeaderDiscount();
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    async addProductList(list) {
      await this.$store.dispatch("setMainLoading", true);
      this.form.product.push(...list);

      if (this.promotionList.length == 0) this.indexEdit = 0;

      await this.setSelectProduct({ index: 0, ...this.form.product[0] });

      await this.calculateTransaction();
    },
    openSlideEditProduct(index) {
      this.setSelectProduct({ index: index, ...this.form.product[index] });
      this.$refs.ModalEditProduct.show(JSON.stringify(this.form), index);
    },
    async openModalEditHeaderDiscount(index) {
      this.indexEdit = await index;
      this.valueEdit = await this.headerDiscountOption[index].promotion_value;
      this.modalEditHeaderDiscount = true;
    },
    onResize() {
      this.isMobile = window.innerWidth < 992;
      this.$refs.modalCustomerList.hide();
      this.$refs.modalAdjustDiscount.hide();
      this.$refs.ModalEditProduct.hide();
      this.$refs.modalCoupon.hide();
      this.$refs.modalProductList.hide();
    },
    async clearPage() {
      await this.$store.dispatch("setMainLoading", true);
      window.location.reload();
    },
    async init() {
      this.selectedCouponList = [];
      this.promotionList = [];
      this.itemDiscountList = [];
      this.headerDiscountOption = [];
      this.indexEdit = 2;
      this.checkedBirthday = false;
      this.alertMsg = "";
      this.alertText = "";
      this.promotionsBirthday = [];
      this.calculateResult = true;

      (this.user = {
        center_point: 0,
        created_time: "",
        credit: 0,
        first_name_th: "",
        last_name_th: "",
        member_id: "",
        member_level_id: 0,
        member_level_name: "",
        point: 0,
        telephone: "",
        user_guid: "",
        user_id: 0,
        valid_user: 1,
        birthday: "",
        consent_status: "",
        sumPoint: 0,
        downgrade_tier_id: 0,
        upgrade_tier_id: 0,
        maintain_baht: 0,
        upgrade_baht: 0,
      }),
        (this.form = {
          saleId: "",
          invoiceNo: "",
          product: [],
          selectedListPromotionId: [],
          promotionCustom: [],
          promotion: [],
          credit: null,
          user_guid: "",
        });

      this.transaction = {
        netPrice: 0,
        discount: 0,
        quantity: 0,
        center_point: 0,
        point: 0,
        sumPoint: 0,
      };

      this.productSelected = {
        id: 0,
        q: 0,
        group_barcode: "",
        sap_discount: [],
        item_discount: [],
        index: 0,
      };
    },
    async editMember() {
      await this.$store.dispatch("setMainLoading", true);

      await this.getUserDetail(this.user.user_guid);
      const customField = await this.$axios(
        `${this.$baseUrl}/customer/GetUserRegisterCustomFieldList/${this.user.user_guid}`
      );
      this.user.custom_field = customField.detail;

      await this.$refs.modalEditMember.show({ ...this.user });
    },
    handleCoupon() {
      if (this.dateTimeFix && this.allowPastCoupon == 0) {
        this.warningAlert("สาขานี้ไม่สามารถใช้คูปองย้อนหลังได้");
      } else if (!this.user.user_guid) {
        this.warningAlert("กรุณาใส่ข้อมูลลูกค้า");
      } else if (this.form.product.length == 0) {
        this.warningAlert("กรุณาเลือกผลิตภัณฑ์ก่อน");
      } else {
        this.$refs.modalCoupon.show(
          this.user,
          this.selecedHeaderDiscountIdList
        );
      }
    },
    async handleSearch() {
      this.checkedBirthday = false;
      this.customerList = [];
      this.$refs.modalCustomerList.show(this.filter.member.search);
    },
    async handleSearchCustomer() {
      this.checkedBirthday = false;
      this.customerList = await this.$refs.modalCustomerList.getCustomers(
        this.filter.member.search,
        true
      );
    },
    async setUserSelect(user) {
      await this.$store.dispatch("setMainLoading", true);
      this.$v.form.$reset();
      await this.init();
      await this.getUserDetail(user.user_guid);
      await this.getHeaderDiscount();
      await this.getPromotionBirthday();
      await this.$store.dispatch("setMainLoading", false);
    },
    async getPromotionBirthday() {
      if (this.user.user_guid) {
        let month = this.$moment(this.user.birthday).month() + 1;
        let monthNow = this.$moment().month() + 1;
        if (month == monthNow) {
          this.promotionsBirthday = await this.headerDiscountOption.filter(
            (item) => item.birthday == 1
          );
          if (!this.checkedBirthday && this.promotionsBirthday.length > 0) {
            this.checkedBirthday = true;
            this.alertMsg = "โปรโมชั่นสำหรับวันเกิด";
            this.alertText =
              "มีโปรโมชั่นสำหรับวันเกิด ยืนยันที่จะใช้โปรโมชั่นหรือไม่";
            this.$refs.modalAlertConfirm.show();
          }
        }
      }
    },
    async confirmUseBirthday() {
      this.form.promotion = [];
      let promotion = this.promotionsBirthday[0];
      await this.form.promotion.push({
        ...promotion,
      });
    },
    async getPaymentChannel() {
      await this.axios
        .get(`${this.$baseUrl}/Transaction/GetPaymentChannel`)
        .then(async (data) => {
          if (data.result == 1) {
            this.paymentChannelList = data.detail;
          }
        });
    },
    async getUserDetail(userGuid, isEdit) {
      await this.$store.dispatch("setMainLoading", true);
      await this.axios
        .get(
          `${this.$baseUrl}/customer/user_lookup_byid/${userGuid}/${this.branchId}`
        )
        .then(async (data) => {
          if (data.result == 1) {
            // await this.$store.dispatch("setMainLoading", false);
            this.filter.member.search = "";
            this.user = data.detail;
            this.getMemberTierUpgrade(data.detail);

            if (this.user.birthday == "01/01/0001 00:00:00")
              this.user.birthday = null;
            else if (this.user.birthday)
              this.user.birthday = this.$moment(
                this.user.birthday,
                "MM/DD/YYYY HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss");

            this.user.sumPoint = data.detail.center_point + data.detail.point;
          }
        });
      if (isEdit) {
        await this.$store.dispatch("setMainLoading", false);
      }
    },
    getMemberTierUpgrade(data) {
      let member = data.member_list.find((el) => el.id == data.member_level_id);

      if (member) {
        if (member.upgrade_tier_id != 0) {
          let upgrade = data.member_list.find(
            (el) => el.id == member.upgrade_tier_id
          );
          if (upgrade) {
            this.user.upgrade_tier_id = member.upgrade_tier_id;
            this.user.upgrade_tier_name =
              upgrade.member_level_language[0].display || upgrade.display;
            this.user.member_level_name =
              member.member_level_language[0].display || member.display;
            this.user.upgrade_baht =
              member.upgrade_baht - Number(data.total_spend);
          }
        } else if (member.downgrade_tier_id != 0) {
          let downgrade = data.member_list.find(
            (el) => el.id == member.downgrade_tier_id
          );

          if (downgrade) {
            this.user.downgrade_tier_id = member.downgrade_tier_id;
            this.user.downgrade_tier_name = downgrade.display;
            this.user.maintain_baht =
              member.maintain_baht - Number(data.total_spend);
          }
        }
      }
    },

    async getDiscount(type, index = null, productId = null) {
      await this.axios
        .get(
          `${this.$baseUrl}/promotion/get_list/${type}/${productId}/${
            this.user.user_guid || " "
          }/${this.$moment().format("YYYY-MM-DD HH:mm:ss")}/${this.branchId}`
        )
        .then(async (data) => {
          if (data.result == 1) {
            if (type == 1) {
              this.promotionList[index] = data.detail;

              await this.promotionList[index].forEach((promotion) => {
                if (!this.form.product[index].sap_discount.length) {
                  this.form.product[index].sap_discount.push({
                    ...promotion,
                  });
                }
              });

              if (this.promotionList[index].length) this.indexEdit = 0;
            } else {
              this.itemDiscountList[index] = data.detail;
            }
          }
        });
    },
    async getHeaderDiscount() {
      await this.axios
        .get(
          `${this.$baseUrl}/promotion/get_header_discount/${
            this.user.user_guid || " "
          }/${this.$moment().format("YYYY-MM-DD HH:mm:ss")}/${this.branchId}`
        )
        .then(async (data) => {
          if (data.result == 1) {
            this.headerDiscountItems = JSON.parse(JSON.stringify(data.detail));
            this.headerDiscountOption = data.detail;
          }
        });
    },
    onChangeSapDiscount() {
      if (this.form.product.length == 0) {
        this.form.product[this.productSelected.index].sap_discount = [];
        this.warningAlert("กรุณาเลือกผลิตภัณฑ์ก่อน");
      } else {
        if (
          this.form.product[this.productSelected.index].sap_discount.length > 1
        ) {
          this.form.product[this.productSelected.index].sap_discount.splice(
            0,
            1
          );
        }
        this.calculateTransaction();
      }
    },
    onChangeItemDiscount() {
      if (this.form.product.length == 0) {
        this.form.product[this.productSelected.index].item_discount = [];
        this.warningAlert("กรุณาเลือกผลิตภัณฑ์ก่อน");
      } else {
        this.calculateTransaction();
      }
    },
    async searchProduct() {
      if (this.user.user_guid) {
        if (this.filter.search.length) {
          const list = await this.findProductSerial();

          if (list.length == 1) {
            this.addProduct(list[0]);
          } else {
            this.$refs.modalProductList.show(this.user, this.filter.search);
          }
        } else {
          this.$refs.modalProductList.show(this.user, this.filter.search);
        }
      } else {
        this.warningAlert("กรุณาใส่ข้อมูลลูกค้า");
      }
    },
    async findProductSerial() {
      // this.isBusy = true;
      let body = {
        articleType: "",
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.filter.search,
        skip: (1 - 1) * 10,
        take: 10,
        spa: this.isSpa,
        booth: this.isBooth,
        branch_id: this.branchId,
      };
      const res = await this.axios.post(
        `${this.$baseUrl}/product/search_product_serial`,
        body
      );
      return res.detail;
    },
    async setSelectProduct(product) {
      this.productSelected = product;
    },
    async addCredit(credit) {
      if (this.form.credit) {
        this.form.credit.net_price =
          parseFloat(this.form.credit.net_price) + parseFloat(credit.net_price);
        this.form.credit.discount_price =
          parseFloat(this.form.credit.discount_price) +
          parseFloat(credit.discount_price);
        this.form.credit.total_price =
          parseFloat(this.form.credit.total_price) +
          parseFloat(credit.total_price);
        this.form.credit.expired_date = credit.expired_date;
      } else {
        this.form.credit = credit;
      }
      this.calculateTransaction();
    },
    deleteCredit() {
      this.form.credit = null;

      this.calculateTransaction();
    },
    async addProduct(product, key) {
      await this.$store.dispatch("setMainLoading", true);
      let pd = this.form.product.map((el) => el.id);
      console.log(product);
      let body = {
        user_guid: this.user.user_guid,
        barcode: product.barcode,
      };
      await this.axios
        .post(`${this.$baseUrl}/product/product_lookup`, body)
        .then(async (data) => {
          if (data.result == 1) {
            this.filter.search = "";
            data.detail.quantity = 1;
            data.detail.group_barcode = "";
            data.detail.serial = product.serial_name;
            data.detail.custom_price = product.serial_price;
            data.detail.sap_discount = [];
            data.detail.item_discount = [];

            await this.form.product.push(data.detail);
            let index = this.form.product.length - 1;
            await this.setSelectProduct({ ...data.detail, index: index });

            if (this.promotionList.length == 0) this.indexEdit = 2;
            await this.getHeaderDiscount();
            await this.getDiscount(1, index, this.form.product[index].id);
            await this.getDiscount(2, index, this.form.product[index].id);
            await this.calculateTransaction();
          } else {
            this.warningAlert(data.message);
          }
          await this.$store.dispatch("setMainLoading", false);
        });
    },
    async adjustDiscount(item, type) {
      item.custom_header_discount =
        item.custom_header_discount || item.promotion_value;
      this.$refs.modalAdjustDiscount.show(item, type);
    },
    async updateHeaderDiscount(promotion, type) {
      if (type == "promotion") {
        let filtered = await this.form.product[
          this.productSelected.index
        ].sap_discount.filter((item) => item.id != promotion.id);
        await filtered.push({
          ...promotion,
        });
        this.form.product[this.productSelected.index].sap_discount = filtered;
      } else if (type == "item") {
        let filtered = await this.form.product[
          this.productSelected.index
        ].item_discount.filter((item) => item.id != promotion.id);
        await filtered.push({
          ...promotion,
        });
        this.form.product[this.productSelected.index].item_discount = filtered;
      } else if (type == "header") {
        let filtered = await this.form.promotion.filter(
          (item) => item.id != promotion.id
        );
        await filtered.push({
          ...promotion,
        });
        this.form.promotion = filtered;

        await this.headerDiscountOption.forEach((element) => {
          if (promotion.id == element.id) {
            element.custom_header_discount = promotion.custom_header_discount;
          }
        });
      }
      this.calculateTransaction();
    },
    async downProductQuantity(index) {
      if (this.form.product[index].quantity > 1) {
        this.form.product[index].quantity -= 1;
      } else {
        this.form.product.splice(index, 1);
      }
      if (this.form.product.length == 0) {
        this.productSelected = {
          id: 0,
          q: 0,
          custom_price: 0,
          group_barcode: "",
          sap_discount: [],
          item_discount: [],
          serial: null,
          custom_price: null,
          index: 0,
        };
      }
      this.calculateTransaction();
    },
    async upProductQuantity(index) {
      if (!this.form.product[index].serial) {
        this.form.product[index].quantity += 1;

        this.calculateTransaction();
      }
    },
    async handleKeyQuantity(index, value) {
      const quantity = parseInt(value, 10);

      this.form.product[index].quantity = quantity;
      if (value < 1) {
        this.form.product.splice(index, 1);
      }
      this.clickQuantity = false;
      this.calculateTransaction();
    },
    changeQuantity(serial) {
      if (!serial) {
        this.clickQuantity = true;
      }
    },
    async checkBirthdayPromotion() {
      let arr1 = this.selecedHeaderDiscountIdList;
      let arr2 = this.promotionsBirthdayId;
      var ret = [];
      arr1.sort();
      arr2.sort();
      for (var i = 0; i < arr1.length; i += 1) {
        if (arr2.indexOf(arr1[i]) > -1) {
          ret.push(arr1[i]);
        }
      }
      // if (ret.length) {
      //   this.warningAlert("โปรโมชั่นวันเกิดไม่สามารถร่วมกับโปรโมชั่นอื่นได้");
      //   this.form.promotion = await this.form.promotion.filter(
      //     (promotion) => ret[0] == promotion.id
      //   );
      // }
    },
    async calculateTransaction() {
      await this.$store.dispatch("setMainLoading", true);

      let product = this.form.product.map((prod, index) => {
        return {
          id: prod.id,
          q: prod.quantity,
          custom_price: prod.custom_price || null,
          group_barcode: "",
          sap_discount: prod.sap_discount,
          item_discount: prod.item_discount,
          serial: prod.serial || null,
        };
      });
      let body = {
        price: [],
        promotion: this.form.promotion,
        product: product,
        credit: this.form.credit,
        customer_id: this.user.user_guid,
        user_id: this.userGuid,
        user_guid: this.userGuid,
        debug: 0,
        branch_id: this.branchId,
        t_datetime: this.dateTimeFix
          ? this.$moment(this.dateTimeFix).format("YYYY-MM-DD HH:mm:ss")
          : this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        user_get_point: this.user_get_point,
      };
      await this.axios
        .post(`${this.$baseUrl}/transaction/calculate_transaction_data`, body)
        .then(async (data) => {
          if (
            (data.result == 1 && !data.error_message) ||
            (data.result == 1 &&
              data.error_message.includes(
                "โปรโมชั่นที่คุณเลือกไม่สามารถร่วมกับโปรโมชั่นอื่นได้"
              )) ||
            (!body.product.length && !data.error_message) ||
            (!body.product.length && this.checkedBirthday)
          ) {
            await this.$store.dispatch("setMainLoading", false);
            this.calculateResult = true;
            await this.form.product.forEach(async (product, i) => {
              let currentProductInfo = data.detail[i];

              if (
                data.error_message.includes(
                  "โปรโมชั่นที่คุณเลือกไม่สามารถร่วมกับโปรโมชั่นอื่นได้"
                )
              ) {
                this.warningAlert(data.error_message);
                this.form.product.forEach((product) => {
                  product.sap_discount = [];
                  product.item_discount = [];
                });

                this.form.promotion = this.form.promotion.filter(
                  (x) => x.id === data.promotion[0].id
                );

                await this.getHeaderDiscount();
              }

              let textPromotion = "";
              let productPromotions = [];
              if (currentProductInfo.header_discount != null) {
                productPromotions.push(currentProductInfo.header_discount);
              }

              if (currentProductInfo.item_discount != null) {
                if (currentProductInfo.item_discount.length > 0) {
                  productPromotions.push(currentProductInfo.item_discount);
                }
              }
              if (currentProductInfo.sap_discount != null) {
                if (currentProductInfo.sap_discount.length > 0) {
                  productPromotions.push(currentProductInfo.sap_discount);
                }
              }

              await productPromotions.forEach(async (item, i) => {
                await productPromotions[i].forEach((item_i, j) => {
                  if (textPromotion != "") {
                    textPromotion += ", ";
                  }
                  textPromotion += productPromotions[i][j].name;
                });
              });

              if (textPromotion != "") {
                currentProductInfo.textPromotion = `โปรโมชั่น: ${textPromotion}`;
              } else currentProductInfo.textPromotion = `โปรโมชั่น: -`;
              this.form.product[i].promotion_detail = currentProductInfo;

              product.price = currentProductInfo.price;
            });

            if (data.price) {
              this.transaction = {
                price: data.price.price_with_no_discount || 0,
                netPrice: data.price.price || 0,
                discount: data.price.discount || 0,
                quantity: data.quantity || 0,
                point: data.center_point + data.point,
                raw_point: data.raw_point,
                is_use_point: data.is_use_point,
              };
            } else {
              this.transaction = {
                price: 0,
                netPrice: 0,
                discount: 0,
                quantity: 0,
                point: 0,
                raw_point: 0,
                is_use_point: 0,
              };
            }

            if (data.detail == null) {
              this.promotionList = [];
              this.itemDiscountList = [];
            }
          } else {
            await this.$store.dispatch("setMainLoading", false);
            this.warningAlert(data.error_message);
            this.calculateResult = false;

            await this.form.product.forEach(async (product, i) => {
              let currentProductInfo = data.detail[i];

              let textPromotion = "";
              let productPromotions = [];
              if (currentProductInfo.header_discount != null) {
                productPromotions.push(currentProductInfo.header_discount);
              }

              if (currentProductInfo.item_discount != null) {
                if (currentProductInfo.item_discount.length > 0) {
                  productPromotions.push(currentProductInfo.item_discount);
                }
              }
              if (currentProductInfo.sap_discount != null) {
                if (currentProductInfo.sap_discount.length > 0) {
                  productPromotions.push(currentProductInfo.sap_discount);
                }
              }

              await productPromotions.forEach(async (item, i) => {
                await productPromotions[i].forEach((item_i, j) => {
                  if (textPromotion != "") {
                    textPromotion += ", ";
                  }
                  textPromotion += productPromotions[i][j].name;
                });
              });

              if (textPromotion != "") {
                currentProductInfo.textPromotion = `โปรโมชั่น: ${textPromotion}`;
              } else currentProductInfo.textPromotion = `โปรโมชั่น: -`;
              this.form.product[i].promotion_detail = currentProductInfo;
            });

            if (data.promotion) {
              const promotionIds = data.promotion.map((p) => p.id);
              this.form.promotion = this.form.promotion.filter((p) =>
                promotionIds.includes(p.id)
              );
            }

            if (data.price) {
              this.transaction = {
                price: data.price.price_with_no_discount || 0,
                netPrice: data.price.price || 0,
                discount: data.price.discount || 0,
                quantity: data.quantity || 0,
                point: data.center_point + data.point,
                raw_point: data.raw_point,
                is_use_point: data.is_use_point,
              };
            } else {
              this.transaction = {
                price: 0,
                netPrice: 0,
                discount: 0,
                quantity: 0,
                point: 0,
                raw_point: 0,
                is_use_point: 0,
              };
            }
          }
        });
    },
    async submit() {
      if (!this.user.user_guid) {
        this.warningAlert("กรุณาเลือกข้อมูลลูกค้า");
        return;
      } else {
        this.$v.form.$touch();
        if (this.$v.form.saleId.$error) {
          this.warningAlert("กรุณากรอกรหัสพนักงาน");
          return;
        } else if (this.form.product.length == 0) {
          this.warningAlert("กรุณาเลือกผลิตภัณฑ์");
          return;
        } else if (this.$v.form.invoiceNo.$error && this.isFullPos == 0) {
          this.warningAlert("กรุณากรอก Invoice No.");
          return;
        }
      }

      if (this.dateTimeFix) {
        this.alertMsg = "ทำรายการย้อนหลัง";
        this.alertText =
          "ตอนนี้กำลังทำรายการย้อนหลัง ยืนยันที่จะทำรายการหรือไม่";
        return this.$refs.modalAlertConfirmTime.show();
      }

      if (this.transaction.is_use_point && this.isFullPos == 0) {
        this.$refs.modalOtp.show({
          telephone: this.user.telephone,
          redeemOrderId: 0,
        });
      } else if (this.isFullPos == 0) {
        await this.submitTransaction();
      } else {
        this.$refs.modalPayment.show(this.user, this.form, this.transaction);
      }
    },
    async confirmPayment(payment) {
      await this.submitTransaction(payment);
    },
    async submitTransaction(payment = null) {
      let product = this.form.product.map((prod, index) => {
        return {
          id: prod.id,
          q: prod.quantity,
          group_barcode: "",
          sap_discount: prod.sap_discount || null,
          item_discount: prod.item_discount || null,
          serial: prod.serial || null,
          custom_price: prod.custom_price || null,
        };
      });

      let credit = null;
      if (this.form.credit) {
        credit = { ...this.form.credit };
        credit.expired_date = this.$moment(credit.expired_date)
          .add(543, "years")
          .format("DD/MM/YYYY");
      }
      let body = {
        price: [],
        promotion: this.form.promotion,
        product: product,
        credit: credit,
        user_guid: this.user.user_guid,
        debug: 0,
        branch_id: this.branchId,
        t_datetime: this.dateTimeFix
          ? this.$moment(this.dateTimeFix).format("YYYY-MM-DD HH:mm:ss")
          : this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        sales_id: this.form.saleId,
        invoice_no: this.form.invoiceNo,
        is_fullpos: this.isFullPos,
        user_get_point: this.user_get_point,
        new_customer: "0",
        tourist: "0",
        partner: "0",
        partner_detail: "",
        zero_point: "0",
        payment_channel_id: payment ? payment.id : 1,
        payment_amount: payment ? payment.amount : 0,
      };
      this.$v.form.$reset();
      await this.$store.dispatch("setMainLoading", true);
      await this.axios
        .post(`${this.$baseUrl}/transaction/submit_transaction_data`, body)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            if (this.isFullPos == 1) {
              this.axios.post("http://localhost:30033/api/PrintReceipt", {
                Base64String: data.print_file,
              });

              this.axios.get("http://localhost:30033/api/cashdrawer");
            }
            await this.$refs.modalBillDetails.show(data, this.user.user_guid);
            this.filter.member.search = "";
            await this.init();
            await this.getHeaderDiscount();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    handleInvoiceNo: function (evt) {
      evt = evt ? evt : window.event;
      if (evt.which === 32) evt.preventDefault();
      else {
        if (this.invoiceLength) {
          evt = evt ? evt : window.event;
          var charCode = evt.which ? evt.which : evt.keyCode;
          if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            (charCode > 105 || charCode < 96)
          ) {
            evt.preventDefault();
          } else {
            let value = evt.target.value;
            let valueLength = value.length;
            let isnum = /^\d+$/.test(parseInt(evt.key));
            let key = isnum ? evt.key : "";
            let repeatStr = "";
            if (evt.keyCode === 8) {
              value = value.substring(0, value.length - 1);
              if (this.isInvoiceLeadingZero)
                repeatStr = "0".repeat(this.invoiceLength - valueLength + 1);
            } else {
              if (this.isInvoiceLeadingZero)
                repeatStr =
                  valueLength < this.invoiceLength
                    ? "0".repeat(this.invoiceLength - valueLength - 1)
                    : "";
            }

            value = `${repeatStr}${value}${key}`;
            value = value.slice(this.invoiceLength * -1);
            this.form.invoiceNo = value;
            evt.preventDefault();
          }
        } else {
          let thai_text =
            "ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ";
          if (thai_text.indexOf(evt.key) >= 0) {
            evt.preventDefault();
          }
        }
      }
    },
    async addCoupon(coupon, addHeader = true) {
      if (!this.selecedHeaderDiscountIdList.includes(coupon.id)) {
        this.selectedCouponList.push(coupon);
        if (addHeader) await this.headerDiscountOption.push(coupon);
        await this.form.promotion.push({
          ...coupon,
        });
      }
      await this.calculateTransaction();
    },
    async addItemDiscount(promotion, selectProductIndex) {
      const index = selectProductIndex;
      const itemDiscounts = this.form.product[index].item_discount;
      const ids = itemDiscounts.map((el) => el.id);

      if (ids.includes(promotion.id)) {
        this.form.product[index].item_discount = itemDiscounts.filter(
          (item) => item.id !== promotion.id
        );
      } else {
        this.form.product[index].item_discount[0] = promotion;
      }
      await this.calculateTransaction();
    },
    async addSapDiscount(promotion, selectProductIndex) {
      const index = selectProductIndex;
      let sapDiscounts = this.form.product[index].sap_discount;
      const ids = sapDiscounts.map((el) => el.id);

      if (ids.includes(promotion.id)) {
        this.form.product[index].sap_discount = sapDiscounts.filter(
          (item) => item.id !== promotion.id
        );
      } else {
        this.form.product[index].sap_discount[0] = promotion;
      }

      this.calculateTransaction();
    },
    deleteSapDiscount(index, selectProductIndex) {
      this.form.product[selectProductIndex].sap_discount.splice(index, 1);
      this.calculateTransaction();
    },
    deleteItemDiscount(index) {
      this.form.product[this.productSelected.index].item_discount.splice(
        index,
        1
      );
      this.calculateTransaction();
    },
    async deleteCoupon(coupon) {
      this.form.promotion = await this.form.promotion.filter(
        (promotion) => promotion.id != coupon.id
      );
      this.headerDiscountOption = await this.headerDiscountOption.filter(
        (promotion) => promotion.id != coupon.id
      );
      this.selectedCouponList = await this.selectedCouponList.filter(
        (coupon) => coupon.id != coupon.id
      );
      await this.calculateTransaction();
    },
    async handleselectPromotion(e) {
      if (this.form.product.length == 0) {
        this.form.promotion = [];
        this.warningAlert("กรุณาเลือกผลิตภัณฑ์ก่อน");
      } else {
        await this.checkBirthdayPromotion();
        await this.calculateTransaction();
      }
    },
    editProduct(detail) {
      this.form = detail;
    },
    selectDiscount(list) {
      this.headerDiscountSelect = list.id;
      this.form.promotion.headerDiscount = list.detail;
    },
    async otpSubmit(status) {
      if (status) {
        await this.submitTransaction();
      }
    },
    handleEnter() {
      const inputElement = this.$refs.saleIdInput.$el.querySelector("input");
      if (inputElement) {
        inputElement.blur();
      }
    },
    async selectSerial(product, index) {
      this.$refs.modalSerialList.show(product, index);
    },
    handleSelectSerial(serial, productId, index) {
      let product = this.form.product[index];

      if (serial) {
        product.serial = serial.serial;
        product.quantity = 1;
        product.custom_price = serial.price;
        this.clickQuantity = false;
      } else {
        product.serial = null;
        product.custom_price = null;
      }
      this.calculateTransaction();
      this.$refs.modalSerialList.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.head-total {
  p {
    text-align: center;
    color: var(--primary-color) !important;
    font-size: 14px;
    font-weight: 600;
  }

  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .col-sm-6 {
    min-width: 133px;
    flex: 1;
    max-width: 100%;
  }
}

.delete-icon {
  width: 40px;
}

.layout-left {
  width: calc(100% - 400px);
  height: calc(100vh - 46px);

  .product-list-container {
    overflow: auto;
    height: calc(100% - 46px - 89px - 87px - 40px);
    position: relative;

    .wrap-no-product {
      width: 100%;
      height: 100%;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;

      .no-product {
        text-align: center;
        font-size: 20px;
        padding: 15px;
        background-color: #f7f7f7;
        border-radius: 5px;
        color: #dcdcdc;
        width: 450px;
      }
    }
  }

  .head-total {
    .col:first-of-type {
      padding-left: 0;
    }

    .col:last-of-type {
      padding-right: 0;
    }

    .bg-secondary {
      height: 100%;
      padding: 15px 0 10px 0;
      background: var(--secondary-color) !important;
    }
  }

  .header-products {
    font-size: 14px;
    font-weight: bold;
    gap: 4px;

    .header-products-item {
      padding: 0px;
    }
  }

  .product-panel {
    background-color: rgb(244, 244, 244) !important;

    &.selected {
      background-color: #bababa2e !important;
    }
  }

  .item-products {
    color: #707070;
    font-size: 16px;
    border-radius: 8px;

    svg {
      color: #000;
    }

    .price {
      font-size: 14px;
      text-align: center;
    }

    .product-info {
      font-size: 12px;
    }

    .normal-unit-price {
      text-decoration: line-through;
    }
  }

  .delete-credit {
    background-color: #000 !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 4px;
    text-align: center;

    svg {
      color: white;
      font-size: 16px;
    }
  }
}

::v-deep .layout-right {
  width: 400px;
  background: #f7f7f7;
  height: calc(100vh - 112px);
  position: relative;

  p.text-title {
    font-size: 14px;
    font-weight: bold;
  }

  .bg-second .input-custom .has-icon input {
    background: var(--secondary-color) !important;
    height: 30px;
  }

  .btn-gift {
    width: 38.66px;
    height: 35px;
    border-radius: 50%;
    border: 1px none #f7f7f7;
    background: white;
    text-align: center;
    padding: 8px 0;

    svg {
      color: var(--primary-color) !important;
      font-size: 22px;
    }
  }

  .btn-main-custom {
    border-radius: 20px;
    height: 35px;
    width: calc((100% - 38.66px - 2.5rem) / 2);
  }

  .tab-discount {
    height: calc(100% - 455px);
    position: relative;

    .wrap-nav-content {
      height: calc(100vh - 555px);
      overflow-y: auto;

      .disabled {
        opacity: 0.7 !important;
      }
    }

    .nav-tabs .nav-link.active {
      border-color: var(--primary-color) !important;
      color: var(--primary-color) !important;
      background: white !important;
    }

    .nav-tabs .nav-link {
      border-bottom: 3px solid #dedede !important;
      background: #dedede !important;
      font-weight: 600;
    }

    .promotion-name {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      margin-top: 4px;
    }

    .promotion-edit {
      font-size: 12px;
      cursor: pointer;
      margin: 0;
      margin-left: 25px;
      text-decoration: underline;
      padding: 0 !important;
      color: #333 !important;
    }

    .promotion-right {
      font-weight: bold;
      color: var(--primary-color);
      text-align: right;

      .promotion-result {
        font-size: 16px;
      }

      .promotion-point {
        font-size: 12px;
      }
    }
  }

  .btn-submit {
    border-radius: 0px;
    width: 100%;
    height: 40px;
    font-size: 20px;
    line-height: 20px;
    background: var(--primary-color) !important;
    color: white !important;
  }

  .img-coupon {
    cursor: pointer;
    width: 32px;
    margin-left: 10px;
  }

  .panel-coupon {
    .input-custom .has-icon input {
      background: #cbcbcb;
      color: white !important;
      text-align: start;
    }

    ::-webkit-input-placeholder {
      color: white !important;
      text-align: start;
    }
    background-color: #f7f7f7;
  }

  .bgc-secondary {
    background: var(--secondary-color) !important;
  }
}

.relative {
  position: relative;
}

::v-deep .detail-input-panel {
  padding-top: 85px;
  background-color: #f7f7f7;
}

@media (max-width: 991px) {
  ::v-deep .layout-right {
    padding-top: 16px !important;
  }
}

@media (max-width: 1200px) {
  .head-total {
    p {
      font-size: 12px;
    }
  }

  .points {
    font-size: 14px !important;
  }

  .text-quantity {
    ::v-deep .custom-input {
      font-size: 12px !important;
      width: 100% !important;
    }
  }
}

@media (min-width: 1199px) {
  .points {
    font-size: 16px !important;
  }
}

.input-h {
  ::v-deep .custom-input {
    height: 30px;
  }

  ::v-deep .input-customs .datetime-container {
    height: 30px;
  }
}

.text-quantity {
  ::v-deep .custom-input {
    text-align: center;
    width: 70%;
    font-size: 16px;
    padding: 0 !important;
  }

  ::v-deep .warp-input {
    display: flex;
    justify-content: center;
  }
}

.text-link {
  text-decoration: underline;
}
.display-user-value {
  height: 30px;
  background-color: white;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
  color: #404040;
  span {
    color: #404040;
    font-size: 10px;
  }
}
</style>
