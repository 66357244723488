<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="modal-title">ปรับส่วนลด</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="d-block">
        <InputText
          v-model="form.custom_header_discount"
          :textFloat="form.name"
          hasIcon
          @onKeypress="isNumber($event)"
          isRequired
          :isValidate="$v.form.custom_header_discount.$error"
          :v="$v.form.custom_header_discount"
        >
          <template v-slot:icon>
            <p class="m-1">
              {{
                form.promotion_discount_type_id == 1
                  ? "%"
                  : form.promotion_discount_type_id == 2
                  ? "บาท"
                  : form.promotion_discount_type_id == 3
                  ? "แต้ม"
                  : ""
              }}
            </p>
          </template>
        </InputText>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="w-50">
            <b-button
              variant="link"
              :disabled="isLoading"
              @click="resetPromotion"
              >กลับสู่ค่าตั้งต้น
            </b-button>
          </div>
          <div class="d-flex justify-content-between w-50 my-0">
            <b-button
              variant="primary-color"
              class="btn-modal btn-hover w-50 mr-1"
              @click="save"
              :disabled="isLoading"
            >
              ยืนยัน
            </b-button>
            <b-button
              variant="close"
              class="btn-modal btn-hover w-50 ml-1"
              @click="hide('forget')"
              :disabled="isLoading"
            >
              ยกเลิก
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { id: 0, custom_header_discount: "", promotion_value: 0 },
      type: ''
    };
  },
  validations() {
    return {
      form: {
        custom_header_discount: {
          required,
        },
      },
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async show(item, type) {
      this.form = { ...item };
      this.type = type;
      this.$v.form.$reset();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    resetPromotion() {
      this.form.custom_header_discount = this.form.promotion_value;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.$emit("update", this.form, this.type);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.btn-link {
  border-color: transparent !important;
  background-color: transparent !important;
  text-decoration: underline !important;
  padding: 0;
  color: var(--primary-color) !important;
}
</style>
