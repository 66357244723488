<template>
  <div class="d-none">
    <b-modal v-model="showModal" centered>
      <template #modal-header="{ close }">
        <div class="modal-title">ยืนยันรหัส OTP</div>
      </template>

      <div class="text-black">
        ยืนยันรหัส OTP สำหรับหมายเลข {{ selectedItem.telephone }}
      </div>
      <div class="mt-2">
        <InputText
          :textFloat="`Ref.${refCode}`"
          v-model="otpCode"
          class="mb-2"
          placeholder="OTP"
          type="text"
        />
      </div>

      <template #modal-footer="{ hide }">
        <b-button
          variant="primary-color"
          class="btn-modal btn-hover w-50"
          @click="verifyOTP"
        >
          ยืนยัน
        </b-button>

        <b-button
          variant="close"
          class="btn-modal btn-hover w-50"
          :disabled="disabledOTP"
          @click="sendOTP"
        >
          ขอ OTP ใหม่
          {{ countDown <= 119 ? `(${countDown})` : "" }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
  },
  data() {
    return {
      showModal: false,
      refCode: "",
      disabledOTP: true,
      countDown: 120,
      myInterval: null,
      otpCode: "",
      selectedItem: {
        redeem_order_id: 0,
        user_id: 0,
        user_guid: "",
        product_type: 0,
        redeem_name: "",
        redeem_id: 0,
        imge: "",
        image_url: null,
        image_path: null,
        gift: "",
        product_name: null,
        member_id: "",
        name: "",
        first_name_th: "",
        last_name_th: "",
        telephone: "",
        used_point: 0,
        point_used: 0,
        branch_id: 0,
        branch_name: "",
        redeem_date: "",
        status: 0,
        redeem_skip: 0,
        ready_valid: 0,
        cancel_valid: 0,
        status_name: "",
        note: null,
        redeem_item_id: 0,
        description: null,
        is_theme: 0,
        theme_primary_color: null,
        theme_secondary_color: null,
        theme_icon_color: null,
        theme_background: null,
        theme_background_page: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
      userGuid: "getUserGuid",
    }),
  },
  watch: {
    countDown(newValue, oldValue) {
      if (newValue == 0) {
        clearInterval(this.myInterval);
        this.countDown = 120;
        this.disabledOTP = false;
      }
    },
  },
  methods: {
    async show(item) {
      this.selectedItem = { ...item };
      await this.sendOTP();
      //   this.showModal = true;
    },
    hide() {
      clearInterval(this.myInterval);
      this.showModal = false;
    },
    countDownTimer() {
      this.countDown -= 1;
    },
    async sendOTP() {
      await this.$store.dispatch("setMainLoading", true);
      this.otpCode = "";
      let body = {
        telephone: this.selectedItem.telephone,
        redeemOrderId: 0,
      };
      await this.axios
        .post(`${this.$baseUrl}/redeem/SendOTP`, body)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.refCode = data.detail;

            this.disabledOTP = true;
            this.myInterval = setInterval(() => {
              this.countDownTimer();
            }, 1000);
            this.showModal = true;
          } else {
            this.warningAlert(data.message);
          }
        })
        .catch((err) => {
          this.warningAlert(err.message);
        });
    },
    async verifyOTP() {
      let body = {
        telephone: this.selectedItem.telephone,
        redeemOrderId: this.selectedItem.redeem_order_id,
        refCode: this.refCode,
        OTP: this.otpCode,
        // socialId: '',
        // socialProvider: '',
      };
      await this.axios
        .post(`${this.$baseUrl}/redeem/VerifyOTP`, body)
        .then(async (data) => {
          if (data.result == 1) {
            this.$emit("success");
            // await this.confirm();
            this.showModal = false;
          } else {
            this.warningAlert("OTP ไม่ถูกต้อง");
          }
        })
        .catch((err) => {
          this.warningAlert(err.message);
        });
    },
    confirmOtherSuccess() {
      this.$emit("success");
      this.hide();
    },
    //   async confirm() {
    //     let oldStatus = this.selectedItem.status;
    //     if (oldStatus == 3) {
    //       let body = {
    //         new_status: 5,
    //         redeem_order_id: this.selectedItem.redeem_order_id,
    //         user_id: this.userGuid
    //       };
    //       await this.axios
    //         .post(`${this.$baseUrl}/redeem/update_redeem_order_status`, body)
    //         .then(async data => {
    //           if (data.result == 1) {
    //             this.disabled = true;
    //             this.successAlert('เปลียนสถานะเสร็จสิ้น');
    //             this.$emit('success');
    //             this.hide();
    //           } else {
    //             this.warningAlert('ไม่สามารถเปลียนสถานะได้');
    //           }
    //         })
    //         .catch(err => {
    //           this.warningAlert(err.message);
    //         });
    //     } else {
    //       if (oldStatus == 0) this.warningAlert('ไม่มีรายการแลกของสมนาคุณนี้');
    //       else this.warningAlert('ลูกค้าท่านนี้ได้รับของสมนาคุณไปแล้ว');
    //     }
    //   }
  },
};
</script>

<style scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  color: var(--primary-color);
  padding: 15px !important;
}

::v-deep .modal-footer {
  flex-wrap: nowrap;
}
.btn-outline {
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
}
@media (max-width: 768px) {
  ::v-deep .modal-title {
    text-align: center;
    width: 100%;
  }
}
</style>
