<template>
  <div>
    <b-modal
      v-model="showSlide"
      ref="modal"
      centered
      size="lg"
      hide-footer
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="modal-title">แก้ไขข้อมูลสินค้า</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="bg-white border-red">
        <div
          class="p-2 detail-panel"
          v-if="form.product[productSelected.index]"
        >
          <div class="d-flex justify-content-between">
            <label>{{ form.product[productSelected.index].product_id }}</label>
            <label>{{
              form.product[productSelected.index].price | numeral('0,0.00')
            }}</label>
          </div>
          <p class="">{{ form.product[productSelected.index].product_name }}</p>
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start align-items-center">
              <font-awesome-icon
                icon="plus-circle"
                title="up-btn"
                class="mr-2 ml-2"
                @click="upProductQuantity()"
              />
              <InputText
                textFloat=""
                v-model="form.product[productSelected.index].quantity"
                class="w-25"
                placeholder="0"
                type="number"
              />
              <font-awesome-icon
                icon="minus-circle"
                title="down-btn"
                class="mr-2 ml-2"
                @click="downProductQuantity()"
              />
            </div>
            <InputText
              textFloat=""
              v-model="form.product[productSelected.index].price"
              class="w-50"
              placeholder="0"
              type="number"
            />
          </div>
        </div>
        <div class="mt-1 tab-discount">
          <b-tabs v-model="tabCurrent" fill>
            <b-tab :title="`โปรโมชั่น (${promotionList.length})`">
              <div
                v-for="(promotion, i) in promotionList"
                :key="i"
                class="d-flex justify-content-between p-2 border-bottom"
              >
                <b-form-checkbox-group
                  v-if="form.product.length"
                  id="checkbox-promotion"
                  name="checkbox-promotion"
                  v-model="form.product[productSelected.index].sap_discount"
                  @change="calculateTransaction"
                >
                  <b-form-checkbox
                    :value="{
                      id: promotion.id,
                      custom_header_discount: promotion.promotion_value,
                      promotion_value: promotion.promotion_value
                    }"
                  >
                    <div class="promotion-name">{{ promotion.name }}</div>
                  </b-form-checkbox>
                  <div
                    @click="adjustDiscount(promotion, 'promotion')"
                    class="promotion-edit"
                    v-if="promotion.custom_header == 1"
                  >
                    ปรับส่วนลด
                  </div>
                </b-form-checkbox-group>
                <div class="promotion-right">
                  <span class="promotion-result" v-if="IsHeaderItemDiscount">
                    <span v-if="promotion.promotion_value == 0">
                      <span v-if="promotion.point >= 0">
                        ได้รับ {{ promotion.point | numeral('0,0') }}</span
                      >
                    </span>
                    <span v-else>
                      {{ promotion.promotion_value | numeral('0,0') }}</span
                    >
                  </span>
                  <span>
                    {{
                      promotion.promotion_discount_type_id == 1 ||
                      promotion.discount_type == 'percent'
                        ? '%'
                        : promotion.promotion_discount_type_id == 2 ||
                          promotion.discount_type == 'amount'
                        ? 'บาท'
                        : promotion.promotion_discount_type_id == 3 ||
                          promotion.discount_type == 'point'
                        ? 'แต้ม'
                        : ''
                    }}
                  </span>
                  <div
                    class="promotion-point"
                    v-if="promotion.required_point > 0"
                  >
                    {{ promotion.required_point }} P
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="`ส่วนลดรายการ (${itemDiscountList.length})`">
              <div
                v-for="(promotion, i) in itemDiscountList"
                :key="i"
                class="d-flex justify-content-between p-2 border-bottom"
              >
                <b-form-checkbox-group
                  v-if="form.product.length"
                  id="checkbox-promotion-item"
                  v-model="form.product[productSelected.index].item_discount"
                  name="checkbox-promotion-item"
                  @change="calculateTransaction"
                >
                  <b-form-checkbox
                    :value="{
                      id: promotion.id,
                      custom_header_discount: promotion.promotion_value,
                      promotion_value: promotion.promotion_value
                    }"
                  >
                    <div class="promotion-name">{{ promotion.name }}</div>
                  </b-form-checkbox>
                  <div
                    @click="adjustDiscount(promotion, 'item')"
                    class="promotion-edit"
                    v-if="promotion.custom_header == 1"
                  >
                    ปรับส่วนลด
                  </div>
                </b-form-checkbox-group>
                <div class="promotion-right">
                  <span class="promotion-result" v-if="IsHeaderItemDiscount">
                    <span v-if="promotion.promotion_value == 0">
                      <span v-if="promotion.point >= 0">
                        ได้รับ {{ promotion.point | numeral('0,0') }}</span
                      >
                    </span>
                    <span v-else>
                      {{ promotion.promotion_value | numeral('0,0') }}</span
                    >
                  </span>
                  <span>
                    {{
                      promotion.promotion_discount_type_id == 1 ||
                      promotion.discount_type == 'percent'
                        ? '%'
                        : promotion.promotion_discount_type_id == 2 ||
                          promotion.discount_type == 'amount'
                        ? 'บาท'
                        : promotion.promotion_discount_type_id == 3 ||
                          promotion.discount_type == 'point'
                        ? 'แต้ม'
                        : ''
                    }}
                  </span>
                  <div
                    class="promotion-point"
                    v-if="promotion.required_point > 0"
                  >
                    {{ promotion.required_point }} P
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              :title="`ส่วนลดท้ายบิล (${headerDiscountOptionForm.length})`"
            >
              <div class="wrap-nav-content">
                <div
                  v-for="(promotion, i) in headerDiscountOptionForm"
                  :key="i"
                  :class="['d-flex justify-content-between p-2 border-bottom']"
                >
                  <b-form-checkbox-group
                    id="checkbox-promotion-header"
                    v-model="form.promotion"
                    name="checkbox-promotion-header"
                    @change="handleselectPromotion"
                  >
                    <b-form-checkbox
                      :value="{
                        id: promotion.id,
                        custom_header_discount:
                          promotion.custom_header_discount ||
                          promotion.promotion_value,
                        promotion_value: promotion.promotion_value
                      }"
                    >
                      <div class="promotion-name">
                        {{ promotion.name }}
                      </div>
                    </b-form-checkbox>
                    <br />
                    <b-button
                      variant="link"
                      @click="adjustDiscount(promotion, 'header')"
                      class="promotion-edit"
                      v-if="promotion.custom_header == 1"
                    >
                      ปรับส่วนลด
                    </b-button>
                  </b-form-checkbox-group>
                  <div class="promotion-right">
                    <span class="promotion-result" v-if="IsHeaderItemDiscount">
                      <span v-if="promotion.promotion_value == 0">
                        <span v-if="promotion.point >= 0">
                          ได้รับ {{ promotion.point | numeral('0,0') }}</span
                        >
                      </span>
                      <span v-else-if="promotion.custom_header_discount">
                        {{
                          promotion.custom_header_discount | numeral('0,0')
                        }}</span
                      >
                      <span v-else>
                        {{ promotion.promotion_value | numeral('0,0') }}</span
                      >
                    </span>
                    <span>
                      {{
                        promotion.promotion_discount_type_id == 1 ||
                        promotion.discount_type == 'percent'
                          ? '%'
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == 'amount'
                          ? 'บาท'
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == 'point'
                          ? 'แต้ม'
                          : ''
                      }}
                    </span>
                    <div
                      class="promotion-point"
                      v-if="promotion.required_point > 0"
                    >
                      {{ promotion.required_point }} P
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <div class="text-center mobile-footer">
          <div class="mb-2 w-100">
            <b-button class="radius-btn w-75" @click="submitEditProduct"
              ><span>บันทึก</span></b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <ModalAdjustDiscount
      ref="modalAdjustDiscount"
      @update="updateHeaderDiscount"
    />
  </div>
</template>

<script>
import ModalAdjustDiscount from './ModalAdjustDiscount';
import * as moment from 'moment/moment';
import OtherLoading from '@/components/loading/OtherLoading';
import InputText from '@/components/inputs/InputText';
export default {
  name: 'ModalEditProduct',
  components: { OtherLoading, InputText, ModalAdjustDiscount },
  props: {
    user: {
      type: Object,
      isRequired: true
    },
    headerDiscountOption: {
      type: Array,
      isRequired: true
    },
    promotionList: {
      type: Array,
      isRequired: true
    },
    itemDiscountList: {
      type: Array,
      isRequired: true
    },
    data: {
      type: Object,
      isRequired: true
    },
    IsHeaderItemDiscount: {
      type: Boolean,
      isRequired: true
    },
    productSelected: {
      type: Object,
      isRequired: true
    },
    promotionsBirthday: {
      type: Array,
      isRequired: true
    },
    branchId: {
      type: Number,
      isRequired: true
    },
    checkedBirthday: {
      type: Boolean,
      isRequired: true
    }
  },
  data() {
    return {
      showSlide: false,
      index: 0,
      tabCurrent: 0,
      form: this.data,
      headerDiscountOptionForm: this.headerDiscountOption
    };
  },
  watch: {},
  computed: {
    selecedHeaderDiscountIdList() {
      let promotion = this.form.promotion;
      return promotion.map((promotion, index) => promotion.id);
    },
    promotionsBirthdayId() {
      let promotion = this.promotionsBirthday;
      return promotion.map((promotion, index) => promotion.id);
    }
  },
  created() {},
  methods: {
    async checkBirthdayPromotion() {
      let arr1 = this.selecedHeaderDiscountIdList;
      let arr2 = this.promotionsBirthdayId;
      var ret = [];
      arr1.sort();
      arr2.sort();
      for (var i = 0; i < arr1.length; i += 1) {
        if (arr2.indexOf(arr1[i]) > -1) {
          ret.push(arr1[i]);
        }
      }
      if (ret.length) {
        this.warningAlert('โปรโมชั่นวันเกิดไม่สามารถร่วมกับโปรโมชั่นอื่นได้');
        this.form.promotion = await this.form.promotion.filter(
          promotion => ret[0] == promotion.id
        );
      }
    },
    async calculateTransaction() {
      await this.$store.dispatch('setMainLoading', true);
      let product = this.form.product.map((prod, index) => {
        return {
          id: prod.id,
          q: prod.quantity,
          custom_price: prod.price,
          group_barcode: '',
          sap_discount: prod.sap_discount,
          item_discount: prod.item_discount
        };
      });
      let body = {
        price: [],
        promotion: this.form.promotion,
        product: product,
        credit: this.form.credit,
        customer_id: this.user.user_guid,
        user_id: this.user.user_guid,
        user_guid: this.user.user_guid,
        debug: 0,
        branch_id: this.branchId,
        t_datetime:
          this.dateTimeFix || this.$moment().format('YYYY-MM-DD HH:mm:ss')
      };
      await this.axios
        .post(`${this.$baseUrl}/transaction/calculate_transaction_data`, body)
        .then(async data => {
          if (
            (data.result == 1 && !data.error_message) ||
            (!body.product.length && !data.error_message) ||
            (!body.product.length && this.checkedBirthday)
          ) {
            await this.form.product.forEach(async (product, i) => {
              let currentProductInfo = data.detail[i];
              let textPromotion = '';
              let productPromotions = [];
              if (currentProductInfo.header_discount != null) {
                productPromotions.push(currentProductInfo.header_discount);
              }
              if (currentProductInfo.item_discount != null) {
                if (currentProductInfo.item_discount.length > 0) {
                  productPromotions.push(currentProductInfo.item_discount);
                }
              }
              if (currentProductInfo.sap_discount != null) {
                if (currentProductInfo.sap_discount.length > 0) {
                  productPromotions.push(currentProductInfo.sap_discount);
                }
              }

              await productPromotions.forEach(async (item, i) => {
                await productPromotions[i].forEach((item_i, j) => {
                  if (textPromotion != '') {
                    textPromotion += ', ';
                  }
                  textPromotion += productPromotions[i][j].name;
                });
              });

              if (textPromotion != '') {
                currentProductInfo.textPromotion = `โปรโมชั่น: ${textPromotion}`;
              } else currentProductInfo.textPromotion = `โปรโมชั่น: -`;

              this.form.product[i].promotion_detail = currentProductInfo;
            });
          } else {
            this.warningAlert(data.error_message);

            if (data.detail) {
              //sap_discount
              await data.detail.forEach(async (product, i) => {
                this.form.product[i].sap_discount = [];
                await product.sap_discount.forEach(promotion => {
                  this.form.product[i].sap_discount.push({
                    id: promotion.id,
                    custom_header_discount:
                      promotion.custom_header_discount ||
                      promotion.promotion_value,
                    promotion_value: promotion.promotion_value
                  });
                });
              });

              //item_discount
              await data.detail.forEach(async (product, i) => {
                this.form.product[i].item_discount = [];
                await product.item_discount.forEach(promotion => {
                  this.form.product[i].item_discount.push({
                    id: promotion.id,
                    custom_header_discount:
                      promotion.custom_header_discount ||
                      promotion.promotion_value,
                    promotion_value: promotion.promotion_value
                  });
                });
              });
            }

            // header_discount
            if (data.promotion) {
              this.form.promotion = [];
              await data.promotion.forEach(promotion => {
                this.form.promotion.push({
                  id: promotion.id,
                  custom_header_discount: promotion.promotion_value,
                  promotion_value: promotion.promotion_value
                });
              });
            }
          }
        });
      await this.$store.dispatch('setMainLoading', false);
    },
    async handleselectPromotion(e) {
      await this.checkBirthdayPromotion();
      await this.calculateTransaction();
    },
    async adjustDiscount(item, type) {
      item.custom_header_discount =
        item.custom_header_discount || item.promotion_value;
      this.$refs.modalAdjustDiscount.show(item, type);
    },
    async updateHeaderDiscount(promotion, type) {
      if (type == 'promotion') {
        let filtered = await this.form.product[
          this.productSelected.index
        ].sap_discount.filter(item => item.id != promotion.id);
        await filtered.push({
          id: promotion.id,
          custom_header_discount: promotion.custom_header_discount,
          promotion_value: promotion.promotion_value
        });
        this.form.product[this.productSelected.index].sap_discount = filtered;
      } else if (type == 'item') {
        let filtered = await this.form.product[
          this.productSelected.index
        ].item_discount.filter(item => item.id != promotion.id);
        await filtered.push({
          id: promotion.id,
          custom_header_discount: promotion.custom_header_discount,
          promotion_value: promotion.promotion_value
        });
        this.form.product[this.productSelected.index].item_discount = filtered;
      } else if (type == 'header') {
        let filtered = await this.form.promotion.filter(
          item => item.id != promotion.id
        );
        await filtered.push({
          id: promotion.id,
          custom_header_discount: promotion.custom_header_discount,
          promotion_value: promotion.promotion_value
        });
        this.form.promotion = filtered;

        await this.headerDiscountOptionForm.forEach(element => {
          if (promotion.id == element.id) {
            element.custom_header_discount = promotion.custom_header_discount;
          }
        });
      }
      this.calculateTransaction();
    },
    show(form, index) {
      this.tabCurrent = 0;
      this.form = JSON.parse(JSON.stringify(this.data));
      this.headerDiscountOptionForm = JSON.parse(
        JSON.stringify(this.headerDiscountOption)
      );
      this.showSlide = true;
    },
    checkAllDiv(index, d) {
      this.promotionSelect = index;
      this.checkID = d.id;
    },
    hide() {
      this.showSlide = false;
      this.promotionSelect = '';
      this.itemDiscountSelect = '';
      this.headerDiscountSelect = [];
      this.headerDiscountSelectList = [];
    },
    upProductQuantity(index) {
      this.form.product[productSelected.index].quantity += 1;
    },
    downProductQuantity(index) {
      if (this.form.product[productSelected.index].quantity > 1) {
        this.form.product[productSelected.index].quantity -= 1;
      }
    },
    selectTab(tab) {
      this.tabCurrent = tab;
    },
    async openModalEdit(index) {
      this.$emit('onOpenEditHeaderDiscountModal', index);
    },
    submitEditProduct() {
      this.$emit('onEditProduct', this.form, this.headerDiscountOptionForm);
      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.product-card {
  min-height: 88px;
  cursor: pointer;
  color: #333333;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;

  .product-card-detail {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.no-data-item {
  min-height: 88px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #bdbdbd;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;
}

::v-deep .nav-pills .nav-link {
  border-radius: 0rem;
  background-color: #dedede !important;
  white-space: nowrap;
  color: var(--font-primary-color);
  padding: 5px;
  border-bottom: 5px solid #dedede !important;
}
::v-deep .nav-pills .nav-link.active {
  background-color: var(--secondary-color) !important;
  white-space: nowrap;
  color: var(--font-primary-color);
  padding: 5px;
  border-bottom: 5px solid var(--primary-color) !important;
}
.mobile-footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  box-shadow: 0 -10px 20px -5px #737373bf;
  button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}
</style>
