var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-sidebar',{attrs:{"backdrop":"","shadow":"","backdrop-variant":"dark","right":"","aria-labelledby":"sidebar-customer-List-title","no-header":"","lazy":true},on:{"hidden":function($event){return _vm.hide()}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[[_c('div',{staticClass:"sidebar-content",attrs:{"id":"sidebar-customer-List"}},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center p-3"},[_c('label',{staticClass:"header-text"},[_vm._v("Serial")]),_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.hide()}}},[_c('b-icon',{attrs:{"icon":"x-circle-fill"}})],1)]),_c('div',{staticClass:"d-flex justify-content-start align-items-end px-3 py-2 filter"},[_c('InputText',{attrs:{"textFloat":"","type":"text","name":"search_customer","faIcon":"search","className":"mb-2 w-100"},on:{"onEnter":_vm.handleSearch,"onClickIcon":_vm.handleSearch},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('div',[_c('img',{staticClass:"pointer delete-icon",attrs:{"src":require("@/assets/images/icons/bin.png"),"alt":"delete","center":"","width":"32"},on:{"click":function($event){return _vm.clearSearch()}}})])],1)]),_c('div',{staticClass:"serial-list pb-3",attrs:{"id":"serial-list"},on:{"scroll":_vm.loadMore}},[_vm._l((_vm.items),function(serial,index){return _c('div',{key:index,staticClass:"p-2 my-2 bg-white pointer serial-item",on:{"click":function($event){return _vm.selectRow(serial)}}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',{staticClass:"text-m-16",class:{
                      'text-gray-1':
                        _vm.productList.find(
                          (x) => x.serial === serial.serial
                        ) !== undefined 
                    }},[_vm._v(" "+_vm._s(serial.serial)+" ")])]),_c('div',{staticClass:"promotion-point text-m-16 text-right",class:{
                    'text-gray-1':
                      _vm.productList.find((x) => x.serial === serial.serial) !==
                        undefined 
                  }},[_vm._v(" "+_vm._s(serial.price)+" บาท ")])])])}),(_vm.isLoading)?_c('div',{staticClass:"loading-panel"},[_c('OtherLoading')],1):_vm._e()],2)])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }