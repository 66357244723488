<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">แก้ไขวันหมดอายุ</div> </template>
      <b-row class="text-center header-title">
        <b-col md="3"> วันที่ซื้อ </b-col>
        <b-col md="3" class="p-0"> จำนวนเครดิต (บาท) </b-col>
        <b-col md="6"> วันที่หมดอายุ </b-col>
      </b-row>
      <hr />
      <b-row class="text-center content-body">
        <b-col md="3" class="d-flex flex-column align-items-center justify-content-center">
          <div>
            {{
              $moment(form.created_time).format($formatDateNew)
            }}
          </div>
        </b-col>
        <b-col md="3" class="d-flex align-items-center justify-content-center">
          <div>
            {{ form.credit_balance | numeral("0,0.00") }} บาท
          </div>
        </b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-center">
          <InputDatePickerFilter
            name="changeTime"
            ref="changeTime"
            v-model="expired_date"
            @input="changeTime"
            :isValidate="$v.expired_date.$error"
            :v="$v.expired_date"
            className="w-100"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    InputDatePickerFilter,
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
      userGuid: "getUserGuid",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        id: 0,
        expired_date: null
      },
      expired_date: null
    };
  },
  validations() {
    return {
      expired_date: {
        required,
      },
    };
  },
  methods: {
    async show(item) {
      this.form = item;
      this.expired_date = this.$moment(this.form.expired_date).add(-543, 'years').format("YYYY-MM-DDT00:00:00"),

      this.$v.expired_date.$reset();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    changeTime(val) {
      this.form.changeTime = val;
    },
    async save() {
      this.$v.expired_date.$touch();
      if (this.$v.expired_date.$error) return;

      this.isLoading = true;
      let body = {
        id: this.form.id,
        expired_date: this.$moment(this.expired_date).add(543, 'years').format("DD/MM/YYYY"),
        sale_id: this.userGuid,
      }
      try {
        this.isLoading = false;
        await this.axios
          .post(`${this.$baseUrl}/product/update_expired_date_creditbalance`, body)
          .then(async (data) => {
            if (data.result == 1) {
              await this.successAlert();
              await this.$emit('success');
              await this.hide();
            }else{
              this.warningAlert(data.message);
            }
          });
      } catch (error) {
        this.warningAlert(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
</style>
